<template>
  <div style="margin-top: 10px">
    <div style='display: flex;flex-direction: row;justify-content: space-between'>
      <SearchTool ref='refSearchTool' :live-type='2' style='width: 80%' @handleQuery='handleQuery'></SearchTool>
      <export-link :lint-title='`导出`' ref='refExportLink' :can-export="userPermissions.indexOf('live_data_export') !== -1"
                   @export='exportLiveData' />
    </div>

    <!--    表格-->
    <LiveDataPlatformTable ref='refLiveDelegateDataList' :groupId="groupId" :liveType="2"/>
  </div>
</template>

<script>
import ApeTable from '@/components/ApeTable'
import ApeDrawer from '@/components/ApeDrawer'
import { mapGetters } from 'vuex'
import PlatformsRadioGroup from '@/pages/platform/components/PlatformsRadioGroup'
import ArtistSearch from '@/components/artist/ArtistSearch'
import ExportLink from '@/components/export/ExportLink'
import LiveDataPlatformTable from './LiveDataPlatformTable'
import SearchTool from '@/pages/live_data/SearchTool'

export default {
  name: 'LiveDelegateDataList',
  components: {
    SearchTool,
    ApeTable,
    ApeDrawer,
    PlatformsRadioGroup,
    ArtistSearch,
    ExportLink,
    LiveDataPlatformTable
  },
  data() {
    return {
      // 分页的offset,序号列使用
      offset: 0,
      columns: [],
      platforms: [],
      checkedRadio: null,
      dayRange: [
        { label: '近30天', value: 30 },
        { label: '近60天', value: 60 },
        { label: '近90天', value: 90 }
      ],
      ym: null,
      searchCondition: {
        platform_code: null,
        time_value: [],
        artist_id: null
      },
      fileTitle: '',
      orderSort: { live_time: 'desc', id: 'desc' }//默认排序规则
      ,
      GroupDeptOption: [],
      groupId: ''
    }
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  created() {

  },
  mounted() {
    // this.defaultDate()
    // this.getGroup()
  },
  watch: {
    "$route.matched": function (n) {
      if (n.length === 3) {
        // this.defaultDate()
      }
    }
  },
  methods: {
    handleQuery() {
      // console.log(searchCondition)
      this.searchCondition = this.handleSearchCondition()
      this.$refs['refLiveDelegateDataList'].show(this.searchCondition)
    },
    async getGroup() {
      let { list } = await this.$api.getMcnGroupDeptOption('performance_data')
      this.GroupDeptOption = list
    },
    defaultDate() {
      this.checkedRadio = 30
      // 默认选择
      this.choiceTimeData(30)
    },
    getLastDays(days) {
      //几天前的时间
      let day1 = new Date()
      day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000 * days)
      return day1.getFullYear() + '-' + (day1.getMonth() + 1) + '-' + day1.getDate()
    },
    choiceTimeData(days) {
      //选择时间
      let beginTime = this.getLastDays(days)
      let endTime = this.getLastDays(1)
      this.searchCondition.time_value = [beginTime, endTime];
    },
    radioChange() {
      this.choiceTimeData(this.checkedRadio)
    },
    changeTime() {
      this.ym = null
      this.checkedRadio = null
    },
    changeMonth() {
      this.checkedRadio = null
      if (this.ym) {
        let ymArr = this.ym.split('-')
        let day = new Date(parseInt(ymArr[0]), parseInt(ymArr[1]), 0);
        let daycount = day.getDate();//最后一天

        this.searchCondition.time_value = [this.ym + '-01', this.ym + '-' + daycount]
      } else {
        this.searchCondition.time_value = []
      }
    },
    choicePlatform(val) {
      this.searchCondition.platform_code = val;
    },
    chooseArtist(item) {
      if (item) {
        this.searchCondition.artist_id = item.id
      } else {
        this.searchCondition.artist_id = null
      }
    },
    clearCondition() {
      this.searchCondition = {
        nickname: null,
        group_id: null,
        sign_status: 'Y',
        platforms: []
      }
    },
    //获取平台的范围数据
    getPlatformsRange(val) {
      this.platforms = val
    },
    //导出表格
    async exportLiveData() {
      try {
        let searchCondition = this.handleSearchCondition()
        Object.assign(searchCondition, { order: this.orderSort }, { live_type: 2 })
        let response = await this.$api.exportLiveInfos(searchCondition)
        let name = '直播(日播)数据'

        this.$refs.refExportLink.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
      }
    },
    // 处理搜索条件
    handleSearchCondition() {
      return this.$refs['refSearchTool'].handleSearchCondition()
      // let condition = {}
      // if (this.searchCondition.artist_id) {
      //   condition.artist_id = this.searchCondition.artist_id
      // }
      //
      // if (this.searchCondition.platform_code) {
      //   condition.platform_code = this.searchCondition.platform_code
      // }
      //
      // if (this.searchCondition.time_value) {
      //   condition.start_time = this.searchCondition.time_value[0]
      //   condition.end_time = this.searchCondition.time_value[1]
      // }
      // if (this.searchCondition.special_type) {
      //   condition.special_type = this.searchCondition.special_type
      // }
      // if (this.searchCondition.special_venue) {
      //   condition.special_venue = this.searchCondition.special_venue
      // }
      // return condition
    },
  },
}
</script>
